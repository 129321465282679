<template>
  <DashboardContainer>
    <DeskingGrid />
  </DashboardContainer>
</template>

<script>
import DashboardContainer from "../containers/DashboardContainer";
import DeskingGrid from "../components/desking/Desking";

export default {
  name: "Desking",
  beforeCreate: function () {
    var auth = this.$storage.get("auth");
    if (!auth) {
      this.$router.push("/login");
    } else {
      if (!auth.token) {
        this.$router.push("/login");
      }
    }
  },

  components: {
    DashboardContainer,
    DeskingGrid,
  },
  beforeRouteLeave(from) {
    if (this.$store.state.desking == false) {
      this.$root.$emit("action", "leaveit", from);
    }else{
        window.location.href= from.fullPath
    }
  },
};
</script>